import Vue from "vue"
import ProductsInfo from "./products-details"
import Loading from '@/components/widgets/Loading.vue'
import SortedTablePlugin from "vue-sorted-table"
import CreateMiscellaneous from './products-create'
import Paginate from "@/components/widgets/pagination.vue";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})

export default {
  components: {
    Loading,
    ProductsInfo,
    Paginate,
    CreateMiscellaneous
  },
  data() {
    return {
      role: "",
      branch: "",
      loading: false,
      busy: false,
      sortBy: 'name',
      apiStatus: 'ไม่พบข้อมูล',
      totalItem: 0,
      currentPage: 1, 
      currentData: {},
      perPage: 8,
      sortDesc: false,
      keyword: '',
      create: {
        Id: null,
        mode: 'create',
        state: false
      },
      fields: [
        {
          key: "code",
          label: "รหัส",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "type",
          label: "ประเภท",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "name",
          label: "ชื่อสินค้า",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "qualtity",
          label: "จำนวน",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "unit_name",
          label: "หน่วยนับ",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "description",
          label: "รายละเอียด",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "cost",
          label: "ต้นทุน",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "price",
          label: "ราคาขาย",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "status",
          label: "สถานะ",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
          sortable: true,
        },
        {
          key: "note",
          label: "หมายเหตุ",
          thClass: "text-xs-1/2 font-weight-bold",
          tdClass: "text-gray-400 font-weight-light text-xs-1/2",
        },
        { 
          key: 'action', 
          label: '#', 
          thClass: 'font-weight-bold text-xs-1/2',
          class: 'text-gray-400 text-right text-xs-1/2' 
        }
      ],
      items: []
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.onInitData(1)
    })
    this.getRole()
  },
  computed: {
    rows() {
      return this.items.length
    }
  },
  methods: {
    async getRole(){
      const res = await this.$store.dispatch('Auth/getRole', {}, { root: true })
      this.role = res?.data.role
      this.branch = res?.data.branch_id
    },
    onCreate() {
      this.create.Id = null
      this.create.mode = 'create'
      this.create.state = !this.create.state
    },
    onUpdate(id) {
      this.create.Id = id
      this.create.mode = 'update'
      this.create.state = !this.create.state
    },
    onCreateClose(data) {
      if (data.status) {
        this.create.state = false
        setTimeout(() => {
          const currentPage = this.currentPage
          this.onInitData(currentPage)
        }, 500)
      }
    },
    async onInitData(currentPage = 1) {
      try {
        // this.items = []
        this.busy = true
        this.apiStatus = 'กำลังค้นหาข้อมูล..'

        const page = currentPage ? currentPage : 1
        const url = `${Vue.prototype.$host}/api/v1/miscellaneous?page=${page}&keyword=${this.keyword}`

        const result = await Vue.prototype.$axios.get(url)
        setTimeout(() => {
          this.apiStatus = 'ไม่พบข้อมูล'
          this.busy = false
          this.items = []

          if (result) {
            this.currentData = result.data
            this.items = result.data.data
            this.totalItem = result.data.total
          }
        }, 500)
      } catch (error) {
        this.busy = false
        this.apiStatus = 'ไม่พบข้อมูล'
        this.onExceptionHandler(error.response.data.message)
      }
    },
    async Export() {
      try {
        this.loading = true
        await this.$axios.post(`${Vue.prototype.$host}/api/v1/exportMiscellaneousExcel`, {}, {
          responseType: 'blob'
        }).then((response) => {
          const url = URL.createObjectURL(new Blob([response.data], {
            type: 'application/vnd.ms-excel'
          }))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'miscellaneous.xlsx')
          document.body.appendChild(link)
          link.click()
          this.loading = false
        })
      } catch (error) {
        this.loading = false
        this.onExceptionHandler(error.response.data.message);
      }
    }
  }
}
